<template>
  <KeepAlive>
    <b-card-code>
      <validation-observer ref="simpleRules">
        <b-form
        v-if="!editPlace"
          style=""
          @submit.prevent
        >

          <b-row>

            <!--  name -->
            <b-col cols="12">
              <b-form-group
                label="اسم المكان"
                label-for="v-first-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="اسم المكان"
                  rules="required"
                >

                  <b-form-input
                    id="v-first-name"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="اسم المكان "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
   
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"

                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                إضافه
              </b-button>
            
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="back"
                variant="primary"
                style="margin-right:15px;"
                @click="this.back"
              >
                رجوع
              </b-button>
            </b-col>

          </b-row>

        </b-form>
         <b-form
         v-else
          style=""
          @submit.prevent
        >

          <b-row>

            <!--  name -->
            <b-col cols="12">
              <b-form-group
                label="اسم المكان"
                label-for="v-first-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="اسم المكان"
                  rules="required"
                >

                  <b-form-input
                    id="v-first-name"
                    v-model="place.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="اسم المكان "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
   
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"

                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                تعديل
              </b-button>
            
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="back"
                variant="primary"
                style="margin-right:15px;"
                @click="this.back"
              >
                رجوع
              </b-button>
            </b-col>

          </b-row>

        </b-form>

      </validation-observer>
    </b-card-code>
  </KeepAlive>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormCheckboxGroup, BFormRadio, BDropdown, BDropdownItem, BDropdownDivider, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import router from '@/router'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },

  directives: {
    Ripple,
  },
  created(){
    this.GetPlaces();

  },
  data() {
    return {

      // ? Default locale for VeeValidate is 'en'
      locale: 'ar',
      // for validation
      required,
      name: null,
      editPlace:false,
      places:[]
    }
  },
  mounted() {
    if(this.$route.params.id){
      this.place = this.places.GetServicePlaces.find(i=>i.id==this.$route.params.id)
      this.editPlace = true
    }
    // switch to arabic in validation
    localize(this.locale)
  },
  methods: {
async GetPlaces(){
       try {
       const response =  await this.$store.dispatch('GetPlaces');
       if(response.status = 200){
          this.servicePlaces = response.data
     }
      } 
      catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }
    },
    validationForm() {
      
        this.$refs.simpleRules.validate().then(success => {
          if (success&& !this.$route.params.id) {
            // eslint-disable-next-line
            this.AddPlace();
          }
          else{
            this.EditPlace();
          }
        })
    },

    async AddPlace() {
      const place = {
        name: this.name,
      }
        try {
       const response =  await this.$store.dispatch('AddPlace',place);
       if(response.status = 200){
        this.$toast({
            component: ToastificationContent,
            props: {
              title:  'تم الإضافة بنجاح',
              icon: 'CheckIcon',
              variant: 'success',
            },
          }),
       this.$router.push({
        name: 'service-places',
      })
     }
      } 
      catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }

    },
      async EditPlace() {
      const place = {
        id:this.$route.params.id,
        name: this.place.name,
      }
        try {
       const response =  await this.$store.dispatch('UpdatePlace',place);
       if(response.status = 200){
        this.$toast({
            component: ToastificationContent,
            props: {
              title:  'تم التعديل بنجاح',
              icon: 'CheckIcon',
              variant: 'success',
            },
          }),
       this.$router.push({
        name: 'service-places',
      })
     }
      } 
      catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }

    },
    back() {
      router.push('/service-places')
    },
  },
}
</script>
